function ChamadaFinal() {
    return(
        <div className="ChamadaFinal">
            <div className="retention">
                <div className="yellowDiv">
                    <p>Mais de <strong>100 mil documentos assinados</strong><br/> pela nossa solução.</p>
                    <a href="https://app.ledgerdoc.com.br/registrar">
                        <button className="vazio">Comece Grátis!</button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ChamadaFinal;