function DivText(props) {
    return (
        <div className="text">
            <h1>{props.chamada}</h1>
            <p>{props.apoio}</p>
            <form className="formHero" method="get" action="https://app.ledgerdoc.com.br/registrar">
                <input type="mail" placeholder="Digite seu email..." id="email" name="email" />
                <button type="submit" className="complete">Começar</button>
            </form>
            <span>(Não é necessário cartão de crédito!)</span>
        </div>
    )
}

export default DivText;