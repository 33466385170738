import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import BeneficiosRapidos from './components/BeneficiosRapidos';
import ExperimenteDiv from './components/ExperimenteDiv';
import BenficiosGerais from './components/BenficiosGerais';
import DivApi from './components/DivApi';
import ChamadaFinal from './components/ChamadaFinal';
import NumerosAtingidos from './components/NumerosAtingidos';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
        <Header />
        <Hero 
          chamada="Assinatura Digital Para Advogados"
          apoio="Digitalize a rotina de assinatutas de documentos do seu escritório"
          img="advogados"
        />
        <BeneficiosRapidos />
        <ExperimenteDiv />
        <BenficiosGerais />
        <DivApi />
        <ChamadaFinal />
        <NumerosAtingidos />
        <Footer />
    </div>
  );
}

export default App;
