import advogadosImg from '../images/advogados.png';
import DivText from './DivText';
function Hero(props){

    function setImageHero(image){

        if(image === `advogados`){
            return advogadosImg;
        }

    }

    return (
        <div className="hero">
            <div className="retent">
                <div className="retention">
                    <DivText
                        chamada={props.chamada}
                        apoio={props.apoio}
                    />
                    <div className="img">
                        <img src={setImageHero(props.img)} alt="Assinatura digital para advogados!" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero;