import Logo from '../images/logo.svg';
function Header(){

    return(
        <header className="topHeader">
            <div className="retention">
                <img src={Logo} alt="LedgerDoc" />
                <nav>
                    <a href="https://app.ledgerdoc.com.br/login">
                        <button className="simple">Entrar</button>
                    </a>
                    <a href="https://app.ledgerdoc.com.br/registrar">
                        <button className="complete">Começar</button>
                    </a>
                </nav>
            </div>
        </header>
    )
}

export default Header;