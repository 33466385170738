import icone_doc_assinado from '../images/icone_doc_assinado.svg';
import icone_blockchain from '../images/icone_blockchain.svg';
import ContentIconesHorizontal from './ContentIconesHorizontal';

function NumerosAtingidos() {
    return (
        <div className="NumerosAtingidos ContainerHorizontal">
            <div className="retention">

        
                <ContentIconesHorizontal 
                    img={icone_doc_assinado}
                    titulo="+ de 100.000 documentos assinados"
                />      
                
                <ContentIconesHorizontal 
                    img={icone_blockchain}
                    titulo="+ de 250.000 documentos registrados em blockchain" 
                />

            </div>

        </div>
    )
}

export default NumerosAtingidos;