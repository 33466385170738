import DivText from './DivText';
import cadastroEmail from '../cadastroEmail';
import view_sistema from '../images/view_sistema.svg';
function ExperimenteDiv(){
    return (
        <div className="ExperimenteDiv">
            <div className="retention">
                <div className="yellowDiv">
                    <DivText
                        chamada="Ganhe R$ 10,00 para experimentar hoje mesmo!"
                        apoio="Comece grátis a utilizar os recursos de Assinatura Eletrônica, Assinatura ICP-Brasil, Revisão, Rubrica Personalizada, Gestão de Assinaturas."
                        actionClick={cadastroEmail()}
                    />

                    <img src={view_sistema} alt="LedgerDoc" />
                </div>
            </div>
        </div>
    )
}

export default ExperimenteDiv;