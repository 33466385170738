import apiImg from '../images/api.svg';
function DivApi(){
    return(
        <div className="DivApi">
            <div className="retention">
                <div className="text">
                    <span className="destaque">Para desenvolvedores</span>
                    <h2>API de Assinatura de documentos.</h2>
                    <p>Integre facilmente um serviço de assinatura digital ao workflow de assinaturas do seu sistema.</p>
                    <a href="https://ledgertec.com.br/docs/sign-service/comece-por-aqui/">Acesse a documentação aqui!</a>
                </div>
                <img src={apiImg} alt="Imagem de API" />
            </div>
        </div>
    )
}

export default DivApi;