import DivTextGeral from './DivTextGeral';
import simples_assinar from '../images/simples_assinar.svg';
import organize_armazene from '../images/organize_armazene.svg';
function BenficiosGerais(){
    return(
        <div className="BenficiosGerais">
            <div className="retent">
                <div className="divisor">

                    <DivTextGeral
                        titulo="Simples de assinar, fácil de solicitar assinatura"
                        paragrafo="Com a tecnologia LedgerSign, você e seu cliente assinam documentos em poucos cliques."
                    />

                    <img className="rigthImg" src={simples_assinar} alt="LedgerDoc simples de assinar" />

                </div>
                <div className="divisor">

                    <img className="leftImg" src={organize_armazene} alt="LedgerDoc simples de organizar" />

                    <DivTextGeral
                        titulo="Organize, armazene e preserve documentos"
                        paragrafo="Preserve documentos com a mais alta fidelidade e disponibilidade. Você terá o controle de todos os documentos na sua empresa em um só lugar!"
                    />

                </div>
            </div>
        </div>
    )
}

export default BenficiosGerais;