function ContentIconesHorizontal(props) {
    return(
        <div className="ContentIconesHorizontal">
            <img src={props.img} alt={props.titulo} />
            <div className="ContentIconesHorizontal_text">
                <h2>{props.titulo}</h2>
                <p>{props.descricao}</p>
            </div>
        </div>
    )
}

export default ContentIconesHorizontal;