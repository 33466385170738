import ContentIconesHorizontal from './ContentIconesHorizontal';
import documento_icone from '../images/documento_icone.svg';
import assinatura_icone from '../images/assinatura_icone.svg';
import chave_icone from '../images/chave_icone.svg';
function BeneficiosRapidos(){
    return (
        <div className="ContainerHorizontal">
            <div className="retention">
                <ContentIconesHorizontal 
                    img={documento_icone}
                    titulo="Gestão de documentos simplificada" 
                    descricao="Todos os seus documentos podem ser organizados em pastas da forma que desejar. Você vai poder acessa-lós sempre que precisar e em qualquer lugar com acesso a internet."
                />
                <ContentIconesHorizontal 
                    img={assinatura_icone}
                    titulo="Assine documentos digitalmente" 
                    descricao="Assinaturas eletrônicas e digitais. Tudo na palma da mão."
                />
                <ContentIconesHorizontal 
                    img={chave_icone}
                    titulo="Mais segurança para seus contratos" 
                    descricao="Você pode amarzenar de forma segura seus contratos pelo tempo que precisar. Temos cofres seguindo as maiores normas de segurança do mercado."
                />
            </div>
        </div>
    )
}

export default BeneficiosRapidos;